import React from 'react';
import { Box, Button, Link, Typography } from '@mui/material';
import { PathNames } from '../../appConfig/PathNames';
import { useNavigate } from 'react-router-dom';

const ThankYouCompany = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'url("/static/thank-you/company-thank-you.png")',
        width: '100%',
        height: 'auto',
      }}
    >
      <Box>
        <img
          src="/static/thank-you/company.png"
          alt="investor"
          style={{ width: '100%' }}
        />
      </Box>
      <Box sx={{ marginTop: { md: '25px', xs: '10px' } }}>
        <Typography
          sx={{
            fontSize: { md: '75px', xs: '30px' },
            fontFamily: 'Oxygen',
            fontWeight: 800,
            color: '#545151',
            lineHeight: { md: '75px', xs: '50px' },
          }}
        >
          Thank You
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: { md: '25px', xs: '10px' },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center',
          width: { md: '60%', xs: '70%' },
        }}
      >
        <Typography
          sx={{
            fontSize: { md: '25px', xs: '16px' },
            color: '#000',
            fontFamily: 'Oxygen',
            fontWeight: 300,
            letterSpacing: '1.75px',
            lineHeight: { md: '35px', xs: '25px' },
          }}
        >
          Congratulations on stepping into a new phase of growth with
          StockKnocks! Your registration marks the beginning of greater
          exposure and exciting funding possibilities. Leverage our bespoke
          resources to captivate investors and secure capital. Any questions?
          Our expert support team is ready to assist you every step of the way!
        </Typography>
      </Box>
      <Box sx={{ marginTop: { md: '25px', xs: '16px' } }}>
        <Button
          onClick={() => navigate(PathNames.FEED)}
          sx={{
            width: '200px',
            height: '50px',
            backgroundColor: '#009262',
            borderRadius: '10px',
          }}
          variant="contained"
        >
          HOMEPAGE
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Link
          underline="none"
          href="https://www.facebook.com/stockknocks"
          target="_blank"
        >
          <img src="/static/thank-you/facebook.png" alt="facebook.png" />
        </Link>
        <Link
          underline="none"
          href="https://www.linkedin.com/company/stock-knocks"
          target="_blank"
          sx={{ margin: '25px' }}
        >
          <img src="/static/thank-you/LinkedIn.png" alt="LinkedIn.png" />
        </Link>
        <Link
          underline="none"
          href="https://twitter.com/stock_knocks"
          target="_blank"
        >
          <img
            src="/static/thank-you/TwitterX.png"
            alt="TwitterX.png"
            style={{ width: '40px', height: '40px' }}
          />
        </Link>
      </Box>
    </Box>
  );
};

export default ThankYouCompany;

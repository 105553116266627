import React from 'react';
import { Box, Button, Link, Typography } from '@mui/material';
import { PathNames } from '../../appConfig/PathNames';
import { useNavigate } from 'react-router-dom';

const ThankYouInvestor = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'url("/static/thank-you/investor-thank-you.png")',
        width: '100%',
        height: 'auto',
      }}
    >
      <Box>
        <img
          src="/static/thank-you/investor.png"
          alt="investor"
          style={{ width: '100%' }}
        />
      </Box>
      <Box sx={{ marginTop: { md: '25px', xs: '10px' } }}>
        <Typography
          sx={{
            fontSize: { md: '75px', xs: '30px' },
            fontFamily: 'Oxygen',
            fontWeight: 800,
            color: '#545151',
            lineHeight: { md: '75px', xs: '50px' },
          }}
        >
          Thank You
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: { md: '25px', xs: '10px' },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center',
          width: { md: '60%', xs: '70%' },
        }}
      >
        <Typography
          sx={{
            fontSize: { md: '25px', xs: '16px' },
            color: '#000',
            fontFamily: 'Oxygen',
            fontWeight: 300,
            letterSpacing: '1.75px',
            lineHeight: { md: '35px', xs: '25px' },
          }}
        >
          Welcome Aboard! Thank you for joining StockKnocks, where visionary
          investors find unparalleled opportunities. We're thrilled to have you
          with us! Dive into a world of exclusive pre-IPO deals and unique
          investment prospects. Explore, invest, and elevate your portfolio.
          Need guidance? Our dedicated support team is just a click away!
        </Typography>
      </Box>
      <Box sx={{ marginTop: { md: '25px', xs: '16px' } }}>
        <Button
          onClick={() => navigate(PathNames.FEED)}
          sx={{
            width: '200px',
            height: '50px',
            backgroundColor: '#009262',
            borderRadius: '10px',
          }}
          variant="contained"
        >
          HOMEPAGE
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Link
          underline="none"
          href="https://www.facebook.com/stockknocks"
          target="_blank"
        >
          <img src="/static/thank-you/facebook.png" alt="facebook.png" />
        </Link>
        <Link
          underline="none"
          href="https://www.linkedin.com/company/stock-knocks"
          target="_blank"
          sx={{ margin: '25px' }}
        >
          <img src="/static/thank-you/LinkedIn.png" alt="LinkedIn.png" />
        </Link>
        <Link
          underline="none"
          href="https://twitter.com/stock_knocks"
          target="_blank"
        >
          <img
            src="/static/thank-you/TwitterX.png"
            alt="TwitterX.png"
            style={{ width: '40px', height: '40px' }}
          />
        </Link>
      </Box>
    </Box>
  );
};

export default ThankYouInvestor;

import axios, { AxiosResponse } from 'axios';
import { companyDataService } from '../appConfig/AxiosConfig';
import { refreshRewardInfo } from './UserService';


const getUnlistedScreenerMetaData = async (): Promise<any> => {
    try {
        let response: AxiosResponse<any> = await companyDataService.get<any, AxiosResponse<any>>(`/screener/unlisted/meta`);
        return response.data;
    } catch (err: any) {
        throw new Error((err as any)?.response?.data?.message || (err as any)?.message || "Company MetaData read failed.");
    }
};


const getDecryptedUnlistedId = async (_id: string): Promise<any> => {
    try {
        let response = await (await companyDataService.get(`/screener/unlisted/decrypt/${_id}`)).data;
        await refreshRewardInfo();
        return response;
    } catch (err: any) {
        throw new Error((err as any)?.response?.data?.message || (err as any)?.message || "Company details read failed.");
    }
};

const postPerformSearch = async (pageNo: any, pageSize: any, searchParams: any): Promise<any> => {
    try {
        let response: AxiosResponse<any> = await companyDataService.post<any, AxiosResponse<any>>(`/screener/unlisted/filter?page=${pageNo}&pagesize=${pageSize}&count=true`, searchParams);
        return response.data;
    } catch (err: any) {
        throw new Error((err as any)?.response?.data?.message || (err as any)?.message || "Company PerformSearch read failed.");
    }

}

const postPerformDefaultSearch = async (pageNo: any, pageSize: any, searchFilter: any): Promise<any> => {
    try {
        let response: AxiosResponse<any> = await companyDataService.post<any, AxiosResponse<any>>(`/screener/unlisted/filter?page=${pageNo}&pagesize=${pageSize}&count=true`, searchFilter);
        return response.data;
    } catch (err: any) {
        throw new Error((err as any)?.response?.data?.message || (err as any)?.message || "Company PerformDefaultSearch read failed.");
    }

}

const getIPODetails = async (cin: string): Promise<any> => {
    try {
        let response: AxiosResponse<any> = await companyDataService.get<any, AxiosResponse<any>>(`/unlisted/ipo-company-data/${cin}`);
        return response.data;
    } catch (err: any) {
        throw new Error((err as any)?.response?.data?.message || (err as any)?.message || "Company PerformDefaultSearch read failed.");
    }

}

export { getUnlistedScreenerMetaData, getDecryptedUnlistedId, postPerformSearch, postPerformDefaultSearch, getIPODetails };

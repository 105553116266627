import { Box, Button, Card, CardContent, CardHeader, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import UnlockIcon from '../../components/Icons/UnlockIcon';
import { UnlistedFinanceSummary } from '../../model/UnlistedFinanceSummaryResponseModel';
import GetDirectorsCard from './DirectorsCard';
import GetChargesCard from './ChargesCard';
import GetFinancialsCard from './FinancialsCard';
import GetRatingsCard from './RatingsCard';
import GetBalanceSheetCard from './BalanceSheetCard';
import GetProfitAndLostCard from './ProfitAndLostCard';
import GetRatios from './RatiosCard';
import { createDataRequest } from '../../services/UnlockService'
import { DateExtensions } from '../../utility/DateExtensions';
import useStyle from "./Styles";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CancelIcon from '@mui/icons-material/Cancel';
import UnlistedUnlockDialog from '../../components/QuetionDialog/UnlistedUnlockDialog';
import { useSelector } from 'react-redux';
import { ReduxStore } from '../../model/ReduxStore';
import { Notification } from "../../model/Notification";
import { Utility } from '../../utility/Utility';
import { NumberExtensions } from '../../utility/NumberExtensions';
import { RoleUtility } from '../../utility/RoleUtility';
import CompanyUnlockSuccessPopup from '../../components/QuetionDialog/CompanyUnlockSuccessPopup';
import CompanyUnlockFailurePopup from '../../components/QuetionDialog/CompanyUnlockFailurePopup';
import CompanyUnlockWarningPopup from '../../components/QuetionDialog/CompanyUnlockWarningPopup';
import CompanyUnlockLoadingPopup from '../../components/QuetionDialog/CompanyUnlockLoadingPopup';
import { getInstaFinanceProcess, getInstaFinanceRefresh } from '../../services/AdminPanelService';

function GetUnlistedBasicDetails(props: { companyShort: any, companySummary: any, balanceSheetSummaryS: UnlistedFinanceSummary, balanceSheetSummaryC: UnlistedFinanceSummary, type: number, loading: boolean }) {
    return (
        <React.Fragment>
            {GetContent(props.companyShort, props.companySummary?.overview, props.balanceSheetSummaryS, props.balanceSheetSummaryC, props.type)}
            <GetChargesCard
                charges={props.companySummary?.charges_summary}
                type={props.type}
                loading={props.loading}
                updated_on={props.companySummary?.charges_summary?.updated_on} />
            <GetFinancialsCard keys={props.companySummary?.financials_summary?.keys ? (props.companySummary.financials_summary.keys as string[]) : []}
                columns={props.companySummary?.financials_summary?.columns ? (props.companySummary.financials_summary.columns as string[]) : []}
                financials_s={props.companySummary?.financials_summary?.financials}
                financials_c={null}
                type={props.type}
                loading={props.loading}
                updated_on={props.companySummary?.financials_summary?.updated_on} />
            <GetRatingsCard
                ratings={props.companySummary?.ratings_summary?.ratings}
                type={props.type}
                loading={props.loading}
                updated_on={props.companySummary?.ratings_summary?.ratings?.updated_on} />
            <GetBalanceSheetCard balanceSheetSummaryS={props.balanceSheetSummaryS} balanceSheetSummaryC={props.balanceSheetSummaryC} loading={props.loading && props.type !== 9} />
            <GetProfitAndLostCard balanceSheetSummaryS={props.balanceSheetSummaryS} balanceSheetSummaryC={props.balanceSheetSummaryC} loading={props.loading && props.type !== 9} />
            <GetRatios balanceSheetSummaryS={props.balanceSheetSummaryS} balanceSheetSummaryC={props.balanceSheetSummaryC} loading={props.loading && props.type !== 9} />
        </ React.Fragment>
    );
}

const GetContent = (companyShort: any, overview: any, balanceSheetSummaryS: UnlistedFinanceSummary, balanceSheetSummaryC: UnlistedFinanceSummary, type: number) => {
    const classes = useStyle();
    const [showRewardConsumptionDialog, setShowRewardConsumptionDialog] = React.useState(false);
    const [showUnlistedUnlockDialog, setShowUnlistedUnlockDialog] = React.useState<boolean>(false);
    const [showUnlistedUpdateDialog, setShowUnlistedUpdateDialog] = React.useState<boolean>(false);
    const [hasData, setHasData] = React.useState<boolean>((balanceSheetSummaryS && balanceSheetSummaryS.has_data) || (balanceSheetSummaryC && balanceSheetSummaryC.has_data));
    const [link, setLink] = React.useState(false);

    const companyUser = useSelector((state: ReduxStore) => RoleUtility.isPremiumCompanyUserOrPendingCompanyUser(state.userData.user?.role));
    const reward = useSelector((state: ReduxStore) => state.userData?.reward?.current_value || 0);
    const [rewardEnabled] = React.useState(false);

    const [showSuccessPopup, setShowSuccessPopup] = React.useState(false);
    const [showFailurePopup, setShowFailurePopup] = React.useState(false);
    const [showWarningPopup, setShowWarningPopup] = React.useState(false);
    const [showLoadingPopup, setShowLoadingPopup] = React.useState(false);


    const onGetRefreshAndProcessClick = async (): Promise<void> => {
        setShowLoadingPopup(true);
      
        const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
        const timeout = (ms: number) => new Promise((_, reject) => setTimeout(() => reject(), ms));
      
        try {
          // Race between the API call and the 5-minute timeout
          let res = await Promise.race([
            getInstaFinanceRefresh(companyShort?.CIN, companyShort?.companyName, companyShort?._id),
            timeout(300000) // 5 minutes in milliseconds
          ]);
      
          if (res?.failed_list?.length > 0 && res?.failed_list.some((x: { CIN: any; }) => x.CIN === companyShort?.CIN)) {
            setShowFailurePopup(true);
          } else {
            await getInstaFinanceProcess(companyShort?.CIN);
            setShowSuccessPopup(true);
          }
        } catch {
          // Show failure popup for any error or timeout without any error message
          setShowFailurePopup(true);
        } finally {
          await delay(5000); // Delay for 5 seconds
          setShowLoadingPopup(false);
        }
      };
      
    

    const closeLoadingPopup = () => {
        setShowLoadingPopup(false);
    }
    

    const handleClosePopup = () => {
        setShowSuccessPopup(false);
        setShowFailurePopup(false);
        setShowWarningPopup(false);
        window.location.reload();
      };

    function sendDataRequest(type: 'unlock' | 'update') {
        createDataRequest(companyShort._id, type)
            .then(data => {
                let notification: Notification = { message: 'Request Sent Successfully.', type: "success" };
                Utility.notify(notification);
            }).catch((err) => {
                console.error(err);
                let notification: Notification = { message: err?.message || 'Request Sent Failed.', type: "error" };
                Utility.notify(notification);
            }).finally(() => {
                setShowUnlistedUnlockDialog(false);
                setShowUnlistedUpdateDialog(false);
            });
    }

    React.useEffect(() => {
        if (type === 9) {
            setHasData(true);
        }
        else {
            setHasData((balanceSheetSummaryS && balanceSheetSummaryS.has_data) || (balanceSheetSummaryC && balanceSheetSummaryC.has_data));
        }
    }, [balanceSheetSummaryS, balanceSheetSummaryC, type])

    function UnlockJSX() {
        let basicDetailsBottom = document.querySelector<HTMLElement>("#unlisted-basic-details-header")?.getBoundingClientRect()?.bottom || 0;
        return (
            <Box sx={{
                position: 'absolute',
                top: basicDetailsBottom + 100,
                // top: { lg: 580, xl: 580, md: 600, sm: 600, xs: 600 },
                background: "#000000", border: "1px solid #EBEBEB",
                zIndex: 1,
                borderRadius: "12px 12px 12px 12px",
                paddingBottom: "50px",
                paddingX: "40px",
                marginX: "5px"
            }}>
                <Grid container sx={{ display: "flex", justifyContent: "flex-end", marginY: "20px", marginX: "-20px" }}>
                    <CancelIcon sx={{ cursor: "pointer" }}
                        onClick={() => {
                            setShowRewardConsumptionDialog(false);
                        }} />
                </Grid>
                <Grid container>
                    <Typography sx={{
                        color: '#FFFFFF', fontFamily: "Oxygen", fontSize: { md: "32px", xs: "22px" }, fontWeight: "700",
                        lineHeight: { md: "40px", xs: "25px" }, height: "auto"
                    }}>
                        {companyShort?.companyName}
                    </Typography>
                </Grid>
                <Box sx={{ paddingX: { md: "30px", xs: "10px" }, display: "flex", justifyContent: "space-between" }}>
                    <Box display="flex" flexDirection={"column"} marginY={'15px'} alignItems={"center"}>
                        <Typography sx={{
                            color: '#FFFFFF', fontFamily: "Oxygen", fontSize: { md: "18px", xs: "15px" }, fontWeight: "400"
                            , lineHeight: { md: "23px", xs: "18px" }, height: "40px", alignSelf: 'start'
                        }}>
                            You need 100 reward points to {hasData === true ? 'update' : 'unlock'} this
                        </Typography>
                        {reward < 100 && <Typography sx={{
                            color: '#FFFFFF', fontFamily: "Oxygen", fontSize: { md: "13px", xs: "11px" }, fontWeight: "300"
                            , lineHeight: { md: "13px", xs: "11px" }, height: "40px", alignSelf: 'start'
                        }}>
                            You have only {reward === 0 ? 0 : NumberExtensions.formatInTwoDigit(reward || 0)} point.
                        </Typography>}
                    </Box>
                    <Grid item sx={{ marginX: "30px", display: 'flex', alignItems: "center" }}>
                        <Button variant="contained"
                            disabled={reward < 100 ? true : false}
                            sx={{
                                position: 'relative', bottom: 10,
                                height: "40px", widht: "70px", borderRadius: "24px",
                                ":disabled": { color: '#686868', backgroundColor: '#fff', border: '1px solid #fff' }
                            }}
                            onClick={() => {
                                if (hasData) {
                                    setShowUnlistedUpdateDialog(true);
                                }
                                else {
                                    setShowUnlistedUnlockDialog(true);
                                }
                                setShowRewardConsumptionDialog(false);
                            }
                            }>
                            USE
                        </Button>
                    </Grid>
                </Box>

                <Box>
                    <Grid container sx={{}} >
                        <Typography sx={{
                            color: '#FFFFFF', fontFamily: "Oxygen", fontSize: { md: "32px", xs: "22px" }, fontWeight: "700"
                            , lineHeight: { md: "40px", xs: "25px" }, height: "40px"
                        }}>
                            Not enough reward points?
                        </Typography>
                    </Grid>
                    <Grid container spacing={1} sx={{ marginY: '15px', paddingLeft: { md: "40px", xs: "10px" } }} >
                        <Grid container spacing={1} marginY={'2px'}>
                            <Grid item xs={12} md={12}>
                                <Typography variant="body1" sx={{ color: '#FFFFFF' }}>
                                    This is how you can get reward points:
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={5} marginY={'0px'}>
                            <Grid item xs={8} md={8}>
                                <Typography variant="body1" sx={{ color: '#FFFFFF' }}>
                                    Upon sigining up
                                </Typography>
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <Typography variant="body1" sx={{ color: '#FFFFFF' }}>
                                    100
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={5} marginY={'0px'}>
                            <Grid item xs={8} md={8}>
                                <Typography variant="body1" sx={{ color: '#FFFFFF' }}>
                                    Completion of profile
                                </Typography>
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <Typography variant="body1" sx={{ color: '#FFFFFF' }}>
                                    50
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={5} marginY={'0px'}>
                            <Grid item xs={8} md={8}>
                                <Typography variant="body1" sx={{ color: '#FFFFFF' }}>
                                    Each post
                                </Typography>
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <Typography variant="body1" sx={{ color: '#FFFFFF' }}>
                                    30
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={5} marginY={'0px'}>
                            <Grid item xs={8} md={8}>
                                <Typography variant="body1" sx={{ color: '#FFFFFF' }}>
                                    Commenting on post
                                </Typography>
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <Typography variant="body1" sx={{ color: '#FFFFFF' }}>
                                    10
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={5} marginY={'0px'}>
                            <Grid item xs={8} md={8}>
                                <Typography variant="body1" sx={{ color: '#FFFFFF' }}>
                                    Receiving comment
                                </Typography>
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <Typography variant="body1" sx={{ color: '#FFFFFF' }}>
                                    20
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box >)
    }

    return <>
        <Box className={classes.root} id='tabdetail-basicdetails'>
            <Card elevation={0}
                sx={{
                    paddingY: '24px', marginX: '0px', backgroundColor: '#F9F9F9', border: '1px solid #EBEBEB', borderRadius: '8px',
                    opacity: showRewardConsumptionDialog === true ? 0.3 : 1
                }}>
                <CardHeader

                    sx={{ marginX: { sm: "24px", xs: "24px" }, textAlign: 'left', paddingLeft: '0px', paddingTop: '0px', paddingBottom: '32px' }}
                    title={
                        <>
                            <Typography
                                id='unlisted-basic-details-header'
                                sx={{ fontSize: { xs: '16px', md: '24px' } }}
                            >Basic Details</Typography>
                        </>
                    }
                    // action={
                    //     <>
                    //         {!hasData &&
                    //             <Button
                    //                 // onClick={() => {
                    //                 //     if (rewardEnabled === true && companyUser !== true) {
                    //                 //         setShowRewardConsumptionDialog(true);
                    //                 //     }
                    //                 //     else {
                    //                 //         setShowUnlistedUnlockDialog(true);
                    //                 //     }
                    //                 // }}
                    //                 onClick={onGetRefreshAndProcessClick} disabled={showLoadingPopup}
                    //                 variant="contained" color="primary" sx={{ height: '36px', width: '135px', border: '1px solid #0F9764', background: '#F9F9F9', color: '#0F9764 !important', fontSize: '15px', fontWeight: '700', lineHeight: '19px', boxShadow: 'none' }}>
                    //                 UNLOCK &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<UnlockIcon width={'18px'} height={'20px'} />
                    //             </Button>}
                    //         {hasData &&
                    //             <Button
                    //                 onClick={() => {
                    //                     if (rewardEnabled === true && companyUser !== true) {
                    //                         setShowRewardConsumptionDialog(true);
                    //                     }
                    //                     else {
                    //                         setShowUnlistedUpdateDialog(true);
                    //                     }
                    //                 }}
                    //                 variant="contained" color="primary"
                    //                 sx={{
                    //                     height: '36px', width: '135px', border: '1px solid #0F9764', background: '#F9F9F9', color: '#0F9764 !important', fontSize: '15px', fontWeight: '700', lineHeight: '19px', boxShadow: 'none',
                    //                     justifyContent: "center", justifyItems: 'middle', textAlign: 'center'
                    //                 }}>
                    //                 Update &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<UnlockIcon width={'18px'} height={'20px'} />
                    //             </Button>}
                    //         <Tooltip title={link ? 'Copied Link' : 'Copy Link'} sx={{ cursor: "pointer", float: "right" }}>
                    //             <IconButton
                    //                 onClick={() => {
                    //                     window.location.hash = 'tabdetail-basicdetails';
                    //                     navigator.clipboard.writeText(window.location.href);
                    //                     setLink(true);
                    //                 }}
                    //             >
                    //                 <ContentCopyIcon
                    //                     sx={{ color: "#0F9764", fontSize: "20px", cursor: "pointer" }} />
                    //             </IconButton>
                    //         </Tooltip>
                    //     </>
                    // }
                >
                </CardHeader>
                <CardContent sx={{ padding: '0px', paddingBottom: '0px !important' }} className={classes.cardBodyText}>
                    {
                        type !== 9 &&
                        <Box sx={{ marginX: { sm: "24px", xs: "24px" }, marginBottom: '24px' }}>
                            <Grid container>
                                <Grid item>
                                    <Typography variant="body2" gutterBottom sx={{ color: '#686868' }}>
                                        {getDescription(companyShort)}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" gutterBottom sx={{ color: '#686868' }}>
                                        {getIsActiveCompany(companyShort)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    }
                    {
                        type !== 9 &&
                        <Grid container sx={{ marginX: '24px', maxWidth: 'calc(100% - 48px)'  }} spacing={0} >
                            <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        Name of the company
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={8}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {companyShort?.companyName}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        CIN
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={8}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {companyShort?.CIN}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {companyShort?.dateOfRegistration?.length >= 10 && <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        Registration date
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={8}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {companyShort?.dateOfRegistration?.substring(0, 10)}
                                    </Typography>
                                </Grid>
                            </Grid>}
                            {companyShort?.registeredState?.length > 1 && <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        State
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={8}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {companyShort.registeredState}
                                    </Typography>
                                </Grid>
                            </Grid>}
                            {(companyShort?.registrarOfCompanies?.length > 0) && <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        RoC
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={8}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {companyShort?.registrarOfCompanies}
                                    </Typography>
                                </Grid>
                            </Grid>}
                            {companyShort?.companyCategory?.length > 1 && <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        Category
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={8}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {companyShort.companyCategory}
                                    </Typography>
                                </Grid>
                            </Grid>}
                            {companyShort?.companySubCategory?.length > 1 && <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        Sub-Category
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={8}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {companyShort.companySubCategory}
                                    </Typography>
                                </Grid>
                            </Grid>}
                            {companyShort?.companyClass?.length > 1 && <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        Class of company
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={8}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {companyShort?.companyClass}
                                    </Typography>
                                </Grid>
                            </Grid>}
                            <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        Authorized capital
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={8}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {companyShort?.authorizedCapital}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        Paid-up capital
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={8}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {companyShort?.paidUpCapital}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {companyShort?.registeredOfficeAddress?.trim()?.length > 1 && <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" noWrap={false} sx={{ color: '#686868' }}>
                                        Office address
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} lg={6}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {companyShort?.registeredOfficeAddress}
                                    </Typography>
                                </Grid>
                            </Grid>}
                            {companyShort?.registeredCity?.trim()?.length > 1 && <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" noWrap={false} sx={{ color: '#686868' }}>
                                        City
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} lg={6}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {companyShort.registeredCity.trim()}
                                    </Typography>
                                </Grid>
                            </Grid>}
                            {companyShort?.registeredPIN?.toString()?.trim()?.length > 1 && <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" noWrap={false} sx={{ color: '#686868' }}>
                                        PIN
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} lg={6}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {companyShort?.registeredPIN}
                                    </Typography>
                                </Grid>
                            </Grid>}
                            {companyShort?.email?.trim()?.length > 1 && <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        Email
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={8}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {companyShort?.email}
                                    </Typography>
                                </Grid>
                            </Grid>}
                            <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        Status of company
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={8}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {companyShort?.companyStatus}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {companyShort?.latestYearAGM?.trim().length >= 10 && <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        Last AGM Date
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={8}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {companyShort?.latestYearAGM?.trim()?.substring(0, 10)}
                                    </Typography>
                                </Grid>
                            </Grid>}
                            {companyShort?.latestYearFinancialStatement?.trim().length >= 10 && <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        Last Balansheet Date
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={8}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {companyShort?.latestYearFinancialStatement?.trim()?.substring(0, 10)}
                                    </Typography>
                                </Grid>
                            </Grid>}
                            {companyShort?.nicCode && <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        NIC
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={8}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {companyShort.nicCode}
                                    </Typography>
                                </Grid>
                            </Grid>}
                            {(companyShort?.descriptionOfMainActivity?.trim().length > 0
                                || companyShort?.nicSubClass?.trim().length > 0
                                || companyShort?.nicGroup?.trim().length > 0)
                                && <Grid container spacing={1} marginY={'1px'}>
                                    <Grid item xs={4} md={4}>
                                        <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                            Primary activity
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={8}>
                                        <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                            {
                                                companyShort.nicSubClass?.trim()
                                                || companyShort.nicGroup?.trim()
                                                || companyShort.descriptionOfMainActivity?.trim()
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>}
                        </Grid>
                    }
                    {
                        type === 9 &&
                        <Grid container sx={{ marginX: '24px', maxWidth: 'calc(100% - 48px)' }} spacing={0} >
                            <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        CIN
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={8}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {overview?.cin}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        Name
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={8}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {overview?.name}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {
                                (overview?.pan && overview?.pan !== "null") &&
                                <Grid container spacing={1} marginY={'1px'}>
                                    <Grid item xs={4} md={4}>
                                        <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                            PAN
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={8}>
                                        <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                            {overview?.pan}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                            {
                                (overview?.inc_date && overview?.inc_date !== "null") &&
                                <Grid container spacing={1} marginY={'1px'}>
                                    <Grid item xs={4} md={4}>
                                        <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                            Date Of Inc
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={8}>
                                        <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                            {DateExtensions.getDateStringUppercaseMonthFromString(overview?.inc_date, ' ')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                            <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        ROC City
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={8}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {overview?.roc_city}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        Reg Number
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={8}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {overview?.reg_number}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        Category
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={8}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {overview?.category}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        Sub-Category
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={8}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {overview?.sub_category}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        Class
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={8}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {overview?.class}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        Auth Capital
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={8}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {overview?.auth_capital}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" noWrap={false} sx={{ color: '#686868' }}>
                                        Paid Up Capital
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} lg={6}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {overview?.paidup_capital}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        Full Address
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} md={8}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {overview?.full_address}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        Reg State
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={8}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {overview?.reg_state}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        Reg City
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={8}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {overview?.reg_city}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {
                                (overview?.reg_pincode && overview?.reg_pincode !== "null") &&
                                <Grid container spacing={1} marginY={'1px'}>
                                    <Grid item xs={4} md={4}>
                                        <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                            Reg PIN
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={8}>
                                        <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                            {overview?.reg_pincode}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                            {
                                (overview?.book_address && overview?.book_address !== "null") &&
                                <Grid container spacing={1} marginY={'1px'}>
                                    <Grid item xs={4} md={4}>
                                        <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                            Book Address
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={8}>
                                        <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                            {overview?.book_address}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                            <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        MCA Status
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={8}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {overview?.mca_status}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {
                                (overview?.last_agm_date && overview?.last_agm_date !== "null") &&
                                <Grid container spacing={1} marginY={'1px'}>
                                    <Grid item xs={4} md={4}>
                                        <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                            Last AGM Date
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={8}>
                                        <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                            {DateExtensions.getDateStringUppercaseMonthFromString(overview?.last_agm_date, ' ')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                            {
                                (overview?.last_bs_date && overview?.last_bs_date !== "null") &&
                                <Grid container spacing={1} marginY={'1px'}>
                                    <Grid item xs={4} md={4}>
                                        <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                            Last BS Date
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={8}>
                                        <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                            {DateExtensions.getDateStringUppercaseMonthFromString(overview?.last_bs_date, ' ')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                            {
                                (overview?.email && overview?.email !== "null") &&
                                <Grid container spacing={1} marginY={'1px'}>
                                    <Grid item xs={4} md={4}>
                                        <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                            Email
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={8}>
                                        <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                            {overview?.email}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                            {
                                (overview?.website && overview?.website !== "null") &&
                                <Grid container spacing={1} marginY={'1px'}>
                                    <Grid item xs={4} md={4}>
                                        <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                            Website
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={8}>
                                        <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                            {overview?.website}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                            <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        MCA Industry
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} md={8}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {overview?.mca_industry}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        MCA Industry Division
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} md={8}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {overview?.mca_industry_division}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} marginY={'1px'}>
                                <Grid item xs={4} md={4}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        MCA Industry Group
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} md={8}>
                                    <Typography component="div" variant="body2" sx={{ color: '#686868' }}>
                                        {overview?.nicGroup || overview?.mca_industry_group}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    <br />
                    {(hasData === false) ?
                        <Box sx={{ marginX: '24px' }}>
                            <Typography variant="body2" gutterBottom sx={{ color: '#686868' }}>
                                The details of this corporate is not there in our database . We shall start updating the details as soon as you click on the Unlock button. It usually takes us about 24 to 48 hours to work on a corporate.
                            </Typography>
                        </Box> : <React.Fragment></React.Fragment>
                    }
                </CardContent>
            </Card>
        </Box>
        {showRewardConsumptionDialog && UnlockJSX()}
        {
            showUnlistedUnlockDialog && <UnlistedUnlockDialog
                isOpen={showUnlistedUnlockDialog}
                handleClose={() => { setShowUnlistedUnlockDialog(false) }}
                message={'Thanks for requesting. It usually takes us about 24 to 48 hours to work on a corporate.'}
                btnText={'Ok'}
                handleConfirm={() => { sendDataRequest('unlock') }} />
        }
        {
            showUnlistedUpdateDialog && <UnlistedUnlockDialog
                isOpen={showUnlistedUpdateDialog}
                handleClose={() => { setShowUnlistedUpdateDialog(false) }}
                message={'Thanks for requesting. It usually takes us about 24 to 48 hours to work on a corporate.'}
                btnText={'Ok'}
                handleConfirm={() => { sendDataRequest('update') }} />
        }
        {showSuccessPopup && (
        <CompanyUnlockSuccessPopup onClose={handleClosePopup} isOpen={showSuccessPopup}/>
        )}
        {showFailurePopup && (
        <CompanyUnlockFailurePopup onClose={handleClosePopup} isOpen={showFailurePopup}/>
        )}
        {showWarningPopup && (
            <CompanyUnlockWarningPopup
            isOpen={showWarningPopup}
            onClose={() => setShowWarningPopup(false)}
        />
        )}
        {showLoadingPopup && (
        <CompanyUnlockLoadingPopup onClose={closeLoadingPopup} isOpen={showLoadingPopup}/>
        )}

    </>;
}

const getDescription = (companyShort: any): any => {
    let description = `${companyShort?.companyName} was incorporated on ${companyShort?.dateOfRegistration} as a ${companyShort?.companyClass?.toLowerCase()} ${companyShort?.listedUnlisted?.toLowerCase() ?? 'unlisted'}${companyShort?.companyClass?.toLowerCase().includes('company') === true ? '' : ' company'} with the MCA. The Corporate Identification Number of ${companyShort?.companyName} is ${companyShort?.CIN}.  It is classified as ${companyShort?.companyCategory} , ${companyShort?.companySubCategory} and  is engaged in the business of ${companyShort?.principalBusinessActivity} at ${companyShort?.registeredState} under the ${companyShort?.registrarOfCompanies}.`;
    // if (companyShort && companyShort.type &&  companyShort.type != 4) {
    //     description = description + `<p>It is currently an ${companyShort?.companyStatus} company.</p>`;
    // }
    // description = description + `<p>The authorised share capital of ${companyShort?.companyName} is ${companyShort?.authorizedCapital} and its paid up capital is ${companyShort?.paidUpCapital}. As per the MCA records, the last AGM was held on {$agm} and its last balance sheet filing date is {$balancesheet}.</p>
    //     <p> The registered address of { $companyName } is { $basicOverview['registeredOfficeAddress'] }. Its email - id is < a href = 'mailto: {$basicOverview['email']}' target = '_blank' > { $basicOverview['email']}</a > and its website is < a href = 'mailto: {$basicOverview['Website']}' target = '_blank' > { $basicOverview['Website']}</a >.</p >`;

    // if (companyShort && companyShort.type &&  companyShort.type != 4) {
    //     description = description + "<p class='mb-0'>Directors associated with {$companyName} are {$directors}.</p>";
    // }
    return description;
}

const getIsActiveCompany = (companyShort: any): any => {
    let description = '';
    if (companyShort && companyShort.type && companyShort.type != 4) {
        description = `It is currently an ${companyShort?.companyStatus} company.`;
    }
    return description;
}

export default GetUnlistedBasicDetails;
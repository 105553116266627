import { Box, Card, Paper, makeStyles, Typography } from "@mui/material";
import react, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ipoMapper from "./ipodetailmapping.json"
import { getIPODetails } from "../../services/UnlistedScreenerService";

interface IProps {
    cin: string;
}

export function IPODetail({ cin }: IProps) {
    const location = useLocation();
    const [ipoData, setIpoData] = useState<any>();
    useEffect(() => {
        if (cin) {
            getIPODetails(cin).then(x => {
                setIpoData(x);
            });
        }
    }, [cin])

    return <Card
        variant="outlined"
        sx={{
            //   width: { md: '150px' },
            width: 'auto',
            borderRadius: '15px',
            overflow: 'hidden',
            border: '1px solid #EBEBEB',
            background: '#F9F9F9',
            display: 'flex',
            padding: "20px",
            "& .header": {
                color: "#000",
                textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                fontFamily: "Oxygen",
                fontSize: "25px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal"
            },
            "&>div": {
                width: "100%"
            },
            "& table": {
                width: "100%",
                fontFamily: "Oxygen",
                borderSpacing: "0px",
                marginTop: "30px",
                fontWeight: 700
            },
            "& table tr td:first-child": {
                width: "50px",
                textAlign: "center",
                height: "50px"
            },
            "& table tr td:last-child": {
                textAlign: "center",
                color: "#009262"
            },
            "table tr:nth-child(2n+1)": {
                background: "#EBF6F3"
            }
        }}
    >

        {(location.hash == "#tabdetail-issuerdetails" || !location.hash) && <RenderDetails title="Issuer Details" attribute="issuers" ipoData={ipoData} />}
        {location.hash == "#tabdetail-issuerdetails1" && <RenderDetails title="Issue Details" attribute="issue" ipoData={ipoData} />}
        {location.hash == "#tabdetail-contactdetails" && <RenderDetails title="Contact Details" attribute="contactDetails" ipoData={ipoData} />}
        {location.hash == "#tabdetail-subscriptiondetails" && <RenderDetails title="Subscription Details" attribute="subscription" ipoData={ipoData} groupbyRows={3} />}
        {location.hash == "#tabdetail-pre_posthandling" && <RenderDetails title="Pre & Post Holding" attribute="prepostholding" ipoData={ipoData} />}
        {location.hash == "#tabdetail-registrardetails" && <RenderDetails title="Registrar Details" attribute="registrarDetails" ipoData={ipoData} />}

    </Card>
}

export function SubscriptionDetails() {
    return <Box>subscription details</Box>
}

function RenderDetails({ title, attribute, ipoData, groupbyRows }: { title: string; attribute: string, ipoData: any, groupbyRows?: number }) {
    return <Box>
        <Typography className="header">{title}</Typography>
        <table>
            {
                ipoMapper[attribute].map((x, i) => {

                    return <>
                        {(groupbyRows && i > 0 && (i % groupbyRows) == 0) && <tr><td colSpan={3}></td></tr >}
                        <tr>
                            <td><img src={`/ipo/${x.icon}`} /></td>
                            <td>{x.displayname}</td>
                            <td>{x.attribute && ipoData && ipoData[x.attribute]}</td>
                        </tr>
                    </>
                })
            }

        </table>
    </Box >
}
import { AppState } from "../model/AppState";
import { PathNames } from "./PathNames";

const initialAppConfig: AppState = {
    pages: [
        {
            name: PathNames.FAQ_PAGE,
            showSearchBar: false,
            showLeftMenu: false,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: false,
            showFooter: true
        },
        // {
        //     name: PathNames.HOME,
        //     showLeftMenu: true,
        //     enableLeftMenuMinimize: true,
        //     isMinimized: false,
        //     showAdvert: false,
        //     showSearchBar: true,
        //     showFooter: false
        // },
        {
            name: PathNames.ABOUT,
            showLeftMenu: false,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: false,
            showSearchBar: true,
            showFooter: true
        },
        {
            name: PathNames.FEED,
            showSearchBar: true,
            showLeftMenu: true,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: true,
            showFooter: false
        },
        {
            name: PathNames.ACCOUNT,
            showSearchBar: true,
            showLeftMenu: true,
            enableLeftMenuMinimize: true,
            isMinimized: false,
            showAdvert: false,
            showFooter: false
        },
        {
            name: PathNames.SIGNIN,
            showSearchBar: false,
            showLeftMenu: false,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: false,
            showFooter: false
        },
        {
            name: PathNames.SIGNUP,
            showSearchBar: false,
            showLeftMenu: false,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: false,
            showFooter: false
        },
        {
            name: PathNames.SIGNUP,
            showSearchBar: false,
            showLeftMenu: false,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: false,
            showFooter: false
        },
        {
            name: PathNames.VIEWED_UNLISTED_COMPANIES,
            showSearchBar: false,
            showLeftMenu: true,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: false,
            showFooter: false
        },
        {
            name: PathNames.UNLISTED_COMPANIES_WITH_FINANCIAL,
            showSearchBar: false,
            showLeftMenu: true,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: false,
            showFooter: false
        },
        {
            name: PathNames.COMPANIES_COVERED_BY_STOCK_KNOCKS,
            showSearchBar: false,
            showLeftMenu: true,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: false,
            showFooter: false
        },
        {
            name: PathNames.UNLOCKED_UNLISTED_COMPANIES,
            showSearchBar: false,
            showLeftMenu: true,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: false,
            showFooter: false
        },
        {
            name: PathNames.LISTED,
            showLeftMenu: true,
            enableLeftMenuMinimize: true,
            isMinimized: false,
            showAdvert: true,
            showSearchBar: true,
            showFooter: false
        },
        {
            name: PathNames.UNLISTED,
            showLeftMenu: false,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: false,
            showSearchBar: true,
            showFooter: false
        },
        {
            name: PathNames.UNLISTED_SCREENER,
            showLeftMenu: false,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: false,
            showSearchBar: true,
            showFooter: false
        },
        {
            name: PathNames.STARTUP,
            showSearchBar: false,
            showLeftMenu: false,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: false,
            showFooter: false
        },
        {
            name: `${PathNames.UNLISTED_DIRECTORY}`,
            showLeftMenu: false,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: false,
            showSearchBar: false,
            showFooter: true
        },
        {
            name: `${PathNames.UNLISTED_PREVIEW}`.replace(/:(.*)/,'*'),
            showLeftMenu: false,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: false,
            showSearchBar: false,
            showFooter: true
        },
        {
            name: "/company/*",
            showLeftMenu: true,
            enableLeftMenuMinimize: false,
            isMinimized: true,
            showAdvert: true,
            showSearchBar: true,
            showFooter: false
        },
        {
            name: PathNames.UnlistedCompanyOverview,
            showLeftMenu: true,
            enableLeftMenuMinimize: false,
            isMinimized: true,
            showAdvert: true,
            showSearchBar: true,
            showFooter: false
        },
        {
            name: PathNames.VIEW_USER,
            showLeftMenu: true,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: true,
            showSearchBar: true,
            showFooter: false
        },
        {
            name: PathNames.ResetPassword,
            showLeftMenu: false,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: false,
            showSearchBar: true,
            showFooter: false
        },
        {
            name: PathNames.LISTEDFAQ,
            showLeftMenu: false,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: false,
            showSearchBar: true,
            showFooter: true
        },
        {
            name: PathNames.UNLISTEDFAQ,
            showLeftMenu: false,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: false,
            showSearchBar: true,
            showFooter: true
        },
        {
            name: PathNames.COMUNITYFAQ,
            showLeftMenu: false,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: false,
            showSearchBar: true,
            showFooter: true
        },
        // {
        //     name: PathNames.UNLISTED,
        //     showSearchBar: true,
        //     showLeftMenu: true,
        //     enableLeftMenuMinimize: false,
        //     isMinimized: false,
        //     showAdvert: true,
        //     showFooter: false
        // },
        // {
        //     name: PathNames.WATCH_LIST,
        //     showSearchBar: true,
        //     showLeftMenu: true,
        //     enableLeftMenuMinimize: false,
        //     isMinimized: false,
        //     showAdvert: true,
        //     showFooter: false
        // },
        {
            name: PathNames.BLOGS,
            showSearchBar: true,
            showLeftMenu: false,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: true,
            showFooter: true
        },
        {
            name: PathNames.CHANNELS,
            showSearchBar: true,
            showLeftMenu: true,
            enableLeftMenuMinimize: false,
            isMinimized: true,
            showAdvert: true,
            showFooter: false
        },
        {
            name: PathNames.CHANNELPREVIEW,
            showSearchBar: true,
            showLeftMenu: false,
            enableLeftMenuMinimize: false,
            isMinimized: true,
            showAdvert: true,
            showFooter: false
        },
        {
            name: PathNames.USER_PREVIEW,
            showSearchBar: true,
            showLeftMenu: false,
            enableLeftMenuMinimize: false,
            isMinimized: true,
            showAdvert: true,
            showFooter: false
        },
        {
            name: PathNames.CAREERS,
            showSearchBar: true,
            showLeftMenu: false,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: false,
            showFooter: true
        },
        {
            name: PathNames.TRENDING_USERS,
            showSearchBar: true,
            showLeftMenu: true,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: true,
            showFooter: false
        },
        {
            name: PathNames.NOTIFICATION,
            showSearchBar: true,
            showLeftMenu: true,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: true,
            showFooter: false
        },
        // {
        //     name: PathNames.PORTFOLIO,
        //     showLeftMenu: true,
        //     enableLeftMenuMinimize: false,
        //     isMinimized: true,
        //     showAdvert: true,
        //     showSearchBar: true,
        //     showFooter: false
        // },
        {
            name: PathNames.Dashboard,
            showLeftMenu: false,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: false,
            showSearchBar: false,
            showFooter: false
        },
        {
            name: PathNames.MARKETPLACE,
            showLeftMenu: false,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: false,
            showSearchBar: false,
            showFooter: true
        },
        {
            name: `${PathNames.MARKETPLACE}/*`,
            showLeftMenu: false,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: false,
            showSearchBar: false,
            showFooter: true
        },
        {
            name: PathNames.CREDIT_HISTORY,
            showLeftMenu: true,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: true,
            showSearchBar: true,
            showFooter: false
        },
        {
            name: PathNames.THANK_YOU,
            showLeftMenu: false,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: false,
            showSearchBar: false,
            showFooter: false
        },
        {
            name: `${PathNames.DEVELOPERSUI}/*`,
            showLeftMenu: false,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: false,
            showSearchBar: false,
            showFooter: false
        }        
    ],
    rtlEnabled: false,
    darkModeEnabled: false,
    loading: false,
    appVersion: "1.0.0",
    companiesData: {
        lastUpdated: new Date(),
        companies: []
    },
    notifications: [],
    notifierConfig: { position: "topCenter" }
}

export default initialAppConfig;
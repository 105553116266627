import React, { useEffect } from 'react';
import ThankYouInvestor from './ThankYouInvestor';
import ThankYouCompany from './ThankYouCompany';
import { useSelector } from 'react-redux';
import { RoleUtility } from '../../utility/RoleUtility';
import { ReduxStore } from '../../model/ReduxStore';

const ThankYou = () => {
  const companyUser = useSelector((state: ReduxStore) =>
    RoleUtility.isPremiumCompanyUserOrPendingCompanyUser(
      state.userData.user?.role
    )
  );

  useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'conversion', { 'send_to': 'AW-10840157637/QMs5CLj7xbQZEMXb_rAo' });
    }
  }, []);

    return <>{companyUser ? <ThankYouCompany /> : <ThankYouInvestor />}</>;
    // return <>
    //     <ThankYouCompany />
    //     <ThankYouInvestor />
    // </>;
};

export default ThankYou;

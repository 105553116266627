import axios from "axios";

const makePayment = (tokenResponse, onClose) => {
  var config = {
    "root": "",
    "style": {
      "bodyBackgroundColor": "#fafafb",
      "bodyColor": "",
      "themeBackgroundColor": "#0FB8C9",
      "themeColor": "#ffffff",
      "headerBackgroundColor": "#284055",
      "headerColor": "#ffffff",
      "errorColor": "",
      "successColor": "",
      "card": {
        "padding": "",
        "backgroundColor": ""
      }
    },
    "data": {
      "orderId": tokenResponse._id,
      "token": tokenResponse.txnToken,
      "tokenType": "TXN_TOKEN",
      "amount": tokenResponse.amount /* update amount */
    },
    "payMode": {
      "labels": {},
      "filter": {
        "exclude": []
      },
      "order": [
        "CARD",
        "NB",
        "UPI"
      ]
    },
    "flow": "DEFAULT",
    "merchant": {
      "mid": tokenResponse.mid,
      "name": "Stockknocks",
      "redirect": true
    },
    "handler": {
      "transactionStatus":
        async function transactionStatus(paymentStatus) {
          console.log(paymentStatus);
          axios.post("/transact/transactionstatus", paymentStatus);
        },
      "notifyMerchant":
        function notifyMerchant(eventName, data) {
          console.log('event name', eventName);
          console.log('data', data);
          console.log("Closed");
        },
      "deferred":
        function deferred(eventType, data) {
          console.log(`Deferred: ${eventType}`);
        },
      "closed":
        function closed() {
          onClose && onClose();
        }
    }
  };
  if (window.Paytm && window.Paytm.CheckoutJS) {
    window.Paytm.CheckoutJS.init(config)
      .then(function onSuccess() {
        window.Paytm.CheckoutJS.invoke();
      }).catch(function onError(error) {
        console.log("Error => ", error);
      });
  }
}

export { makePayment }
export enum PathNames {
    // "HOME" = "/home",
    "PAYMENTSUCCESS" = '/payment-success',
    "PAYMENTFAILURE" = '/payment-failure',
    "PAYMENTREFUND" = '/payment-refund-policy',
    "COMUNITYFAQ" = "/investor-community-channel-india",
    "LISTEDFAQ" = "/listed-shares-research-india",
    "UNLISTEDFAQ" = "/unlisted-shares-research-india",
    "NEWSBLOCK" = "/newsblock/:id",
    "PROFILE" = "/profile",
    "LISTED" = "/listed",
    "UNLISTED" = "/unlisted",
    "STARTUP" = "/listed",
    "SIGNIN" = "/signin",
    "SIGNUP" = "/signup",
    "SIGNOUT" = "/signout",
    "ACCOUNT" = "/account",
    "DeploymentHistory" = "/history",
    "CompanyOverviewWithCode" = "/company/:code",
    "CompanyOverview" = "/company/:code/companyname/:name",
    "AskMeListed" = "/askme/company/:code/",
    "CompanyOverviewWithName" = "/company/:coname/:cocode",
    "UnlistedCompanyOverview" = "/unlistedcompany",
    "ABOUT" = "/about",
    "ResetPassword" = "/password_reset",
    "FAQ_PAGE" = "/faq",
    "ADMIN_PANEL" = "/adminPanel",
    "Dashboard" = "/dashboard",
    "FEED" = "/community",
    "VIEW_USER" = "/viewuser",
    "CHANNELS" = "/channels",
    "CHANNELPREVIEW" = "/cp/*",
    // "WATCH_LIST" = "/watchlist",
    "USER_PREVIEW" = "/userpreview/*",
    "UNLISTED_PREVIEW" = "/preview/unlisted/:companyName",
    "UNLISTED_DIRECTORY" = "/company/unlisted-directory/*",
    "BLOGS" = "/blogs/*",
    "OUR_BLOGS" = "/blogs",
    "CAREERS" = "/careers",
    "TRENDING_USERS" = "/trendingUsers",
    "NOTIFICATION" = "/notifications",
    "PORTFOLIO" = "/portfolio",
    "PREMIUM" = "/premium-purchase",
    "PLANS" = "/premium-plans",
    "TRANSACT" = "/transact",
    "CONTACTUS" = "/contact-us",
    "NOT_FOUND" = "*",
    "NOT_FOUND_PAGE" = "/not-found",
    //bidhan 18th jan 2023//
    "JOIN" = "/join",
    "MARKETPLACE" = "/marketplace",
    "DEVELOPERSUI" = "/developer-ui",
    "UNLISTED_SCREENER" = "/unlisted-screener",
    "CREDIT_HISTORY" = "/credit-history",
    "THANK_YOU" = "/thank-you",
    "VIEWED_UNLISTED_COMPANIES" = "/unlisted-viewed-companies",
    "UNLOCKED_UNLISTED_COMPANIES"="/unlisted-unlocked-companies",
    "UNLISTED_COMPANIES_WITH_FINANCIAL" = "/unlisted-companies-with-financial",
    "COMPANIES_COVERED_BY_STOCK_KNOCKS" = "/companies-covered-by-stock-knocks",
}
import { Avatar, Box, Button, CircularProgress, Divider, FormControl, MenuItem, OutlinedInput, Select, SelectChangeEvent, Typography } from "@mui/material";
import { SetStateAction, useEffect, useState } from "react";
import { MultiselectControl } from "./MultiselectControl";
import { Link, useNavigate } from "react-router-dom";
import { ToogleSwitch } from "./BooleanControl";
import { AmountMinMaxControl } from "./AmountMinMax";
import MultiselectPinControl from "./MultiselectPinControl";
import { getDecryptedUnlistedId, getUnlistedScreenerMetaData, postPerformDefaultSearch, postPerformSearch } from "../../services/UnlistedScreenerService";
import { useSelector } from "react-redux";
import { ReduxStore } from "../../model/ReduxStore";
import { Utility } from "../../utility/Utility";
import UnlistedScreenerGoToPlan from "../../components/QuetionDialog/UnlistedScreenerGoToPlan";
import UnlistedScreenerUnlock from "../../components/QuetionDialog/UnlistedScreenerUnlock";
import { UnlistedScreenerItemBox } from "./UnlistedScrennerItemBox";

interface IMetadata {
    fields: IField[];
}

interface IField {
    enabled: boolean;
    fieldSettings: any;
    label: string;
    name: string;
    operators: string[];
    preferWidgets: string[];
    rowId: string;
    searchEnabled: boolean;
    type: string;
    dependsOn?: {
        visibility: string;
    }
    hint?: string;
}

export function UnlistedScreener() {
    const reward = useSelector((state: ReduxStore) => state.userData.reward);
    const navigate = useNavigate();
    const [loadDefaultSearch, setLoadDefaultSearch] = useState(true);

    const [metadata, setMetadata] = useState<IMetadata>()
    const [values, setValues] = useState<any>({ "RegisteredState": [{ title: 'All', value: 'All' }] });
    const [isCompanyListLoading, setCompanyListLoading] = useState(false);

    const [pageSize, setPageSize] = useState(20);
    const [currentPageSize, setCurrentPageSize] = useState(20);
    const [pageNo, setPageNo] = useState(0);

    const [noCompanyFound, setNoCompanyFound] = useState(false);
    const [showGotoPlanDialog, setShowGotoPlanDialog] = useState<boolean>(false);
    const [showUnlockDialog, setShowUnlockDialog] = useState<boolean>(false);
    const [selectedCompany, setSelectedCompany] = useState<any>(null);


    const [companyList, setCompanyList] = useState<{
        unlocked: boolean;
        companyName: string;
        companyStatus: string;
        registeredState: string;
        // registeredOfficeAddress: string;
        principalBusinessActivity: string;
        profile_photo: string;
        paidUpCapital: Number | undefined | null;
        authorizedCapital: Number | undefined | null;
        year: Number | undefined | null;
        net_worth: Number | undefined | null;
        total_income: Number | undefined | null;
        total_expense: Number | undefined | null;
        pbt: Number | undefined | null;
        income_tax: Number | undefined | null;
        pat: Number | undefined | null;
        _id: Number | undefined | null;
    }[]>();

    const [totalRecords, setTotalRecords] = useState(0);


    const handlePageSizeChange = (pageSize: SetStateAction<number>) => {
        setPageSize(pageSize); // Update pageSize state variable
        setPageNo(0); // Reset page number to 0
        setCurrentPageSize(pageSize); // Update current page size
    };

    const onCompanyNameClick = (company: any) => {
        setSelectedCompany(company);
        if ((reward?.credits ?? 0) > 0) {
            setShowUnlockDialog(true);
        } else {
            setShowGotoPlanDialog(true);
        }
    };

    const handleGotoPlanDialogClose = () => {
        setShowGotoPlanDialog(false);
    }

    const handleUnlockDialogClose = () => {
        setShowUnlockDialog(false);
        setSelectedCompany(null);
    }

    const unlockCompany = async () => {
        try {
            setCompanyListLoading(true);
            const decryptedId = await getDecryptedUnlistedId(selectedCompany?._id);
            if (decryptedId) {
                // Update the unlocked company's data in the companyList state
                setCompanyList((companyList) => {
                    return (companyList ?? []).map(company => {
                        if (company._id === selectedCompany?._id) {
                            const updatedCompany = {
                                ...company,
                                _id: decryptedId._id,
                                companyName: decryptedId.companyName,
                                unlocked: true
                            };
                            return updatedCompany;
                        }
                        return company;
                    });
                });
                window.open(`/unlistedcompany?code=${decryptedId._id}`, '_blank');
            }
        }
        catch (error: any) {
            console.error(error);
            Utility.notify({ message: error.message, type: 'error' });
        } finally {
            setCompanyListLoading(false);
            setShowUnlockDialog(false);
            setSelectedCompany(null);
        }
    };

    useEffect(() => {
        (async () => {
            setCompanyListLoading(true);
            const response = await getUnlistedScreenerMetaData();
            const metaData = await response as IMetadata;
            setMetadata(metaData);

            // perform default search
            performDefaultSearch();
            setCompanyListLoading(false);
            setLoadDefaultSearch(false);
        })();
    }, []);

    useEffect(() => {
        if (!loadDefaultSearch) {
            performSearch();
        }
    }, [pageSize, pageNo]);

    const isVisible = (field: IField) => {
        if (field.dependsOn?.visibility) {
            return (function () { return eval(field.dependsOn?.visibility || "") }).call(values);
        }
        else
            return true;
    }

    const renderControl = (field: IField) => {
        if (field.enabled != false && isVisible(field)) {
            if (field.preferWidgets.includes('multiselect') && field.fieldSettings.listValues) {
                return renderMultiselectControl(field);
            } if (field.preferWidgets.includes('singleselect') && field.fieldSettings.listValues) {
                return renderSingleSelectControl(field);
            } else if (field.preferWidgets.includes('boolean') && field.fieldSettings.listValues) {
                return renderBooleanControl(field);
            } else if (field.preferWidgets.includes('numberminmax')) {
                return renderAmountMinMaxControl(field);
            } else if (field.preferWidgets.includes('multiselect_pin')) {
                return renderMultiselectPinControl(field);
            } else if (field.preferWidgets.includes('separator')) {
                return renderSeparator(field);
            }

        }
    }

    const renderSeparator = (control: IField) => {
        return <Box sx={{ textAlign: "center" }}>
            <Typography sx={{ fontSize: "14px", padding: "5px 13px", color: "#878787" }}>{control.label}</Typography>
        </Box>
    }

    const renderMultiselectPinControl = (control: IField) => {
        return <>
            <MultiselectPinControl
                label={control.label}
                value={values[control.name]}
                onChange={(v) => { setValues({ ...values, [control.name]: v }) }}
                hint={control.hint}
            />
        </>
    }

    const renderAmountMinMaxControl = (control: IField) => {
        return <>
            <AmountMinMaxControl
                label={control.label}
                value={values[control.name]}
                onChange={(v) => { setValues({ ...values, [control.name]: v }) }}
            />
        </>
    }

    const renderBooleanControl = (control: IField) => {
        return <>
            <ToogleSwitch
                label={control.label}
                options={control.fieldSettings.listValues}
                value={values[control.name]}
                onChange={(v) => { setValues({ ...values, [control.name]: v }) }}
            />
        </>
    }

    const renderMultiselectControl = (control: IField) => {
        return <>
            <MultiselectControl
                label={control.label}
                onChange={(v) => { setValues({ ...values, [control.name]: v }) }}
                options={control.fieldSettings.listValues}
                values={values[control.name] || []}
                hint={control.hint}
            /></>
    }

    const renderSingleSelectControl = (control: IField) => {
        return <Box sx={{
            borderRadius: "8px",
            padding: "5px 28px"
        }}>
            <Typography sx={{ fontSize: "14px", marginBottom: "5px", color: "#393939" }} component={"div"}>{control.label}</Typography>

            <Select
                sx={{ width: "100%", height: "45px", fontSize: "14px", background: "#ffffff" }}
                value={values[control.name] || "0"}
                onChange={(event: SelectChangeEvent) => {
                    setValues({ ...values, [control.name]: event.target.value });
                }}
            >
                <MenuItem sx={{
                    fontWeight: "lighter",
                    opacity: 0.6,
                    color: "grey",
                    fontSize: "13px"
                }} value={0}>Select {control.label}</MenuItem>
                {
                    control.fieldSettings.listValues.map((x) => (<MenuItem sx={{ fontSize: "14px" }} value={x.value}>{x.title}</MenuItem>))
                }

            </Select>
        </Box>
    }

    const renderFilterControls = () => {
        return (<>
            {
                metadata?.fields.map(field => (
                    <Box sx={{ marginBottom: "10px" }}>
                        {renderControl(field)}
                    </Box>
                ))
            }
        </>)
    }

    function renderAvatarName(name: string) {
        const split = name.split(' ');
        const first = split[0][0];
        let second = '';
        if (split.length > 1)
            second = split[1][0];
        return <span style={{ fontSize: "50px" }}>{`${first}${second}`.toUpperCase()}</span>;
    }

    const renderCompanyList = () => {
        return (
            <Box display="flex" sx={{ flexDirection: { xs: "column", md: "row" }, flexWrap: "wrap", gap: "20px" }}>
                {companyList && companyList.length > 0 && companyList.map(x => (
                    <UnlistedScreenerItemBox data={x} onClick={onCompanyNameClick} showUnlock={true}/>
                ))
                }
            </Box>
        );
    }

    const performSearch = async () => {
        const searchParams: any[] = [];
        metadata?.fields.forEach(field => {
            if (values[field.name] != undefined && isVisible(field) && (typeof (values[field.name]) == "boolean" || (typeof (values[field.name]) == "number" && values[field.name]) || values[field.name].length > 0)) {
                const obj = {} as any;
                obj.name = field.name;
                if (typeof (values[field.name]) == "boolean") {
                    obj.value = (values[field.name]);
                } else if (field.operators.includes("singleselect_equals")) {
                    obj.value = (values[field.name]);
                } else if (field.preferWidgets.includes("multiselect_pin")) {
                    obj.value = ((values[field.name]) || "").trim().split(',').map(x => !isNaN(x) ? parseInt(x) : 0);
                } else if (field.fieldSettings?.listValues) {
                    obj.value = (values[field.name] as any[]).map(x => x.value);
                } else {
                    obj.value = (values[field.name]);
                }
                obj.type = field.preferWidgets[0];
                obj.operator = field.operators[0];
                searchParams.push(obj);
            }
        });

        setCompanyListLoading(true);
        setCompanyList([])
        setNoCompanyFound(false);

        const response = await postPerformSearch(pageNo, pageSize, searchParams);
        const data = await response?.companies || [];
        const total_records = response?.total_records;
        setCompanyListLoading(false);
        setCompanyList(data);
        setTotalRecords(total_records);

        if (data.length == 0) {
            setNoCompanyFound(true);
        }
    }

    const performDefaultSearch = async () => {
        var searchFilter = [
            { "name": "RegisteredState", "value": ["All"], "type": "multiselect", "operator": "multiselect_equals" }
        ]
        setCompanyListLoading(true);
        setCompanyList([])
        const response = await postPerformDefaultSearch(pageNo, pageSize, searchFilter);
        const data = response?.companies || [];
        const total_records = response?.total_records;
        setCompanyListLoading(false);
        setCompanyList(data);
        setTotalRecords(total_records);
    }

    return <Box display={"flex"} sx={{ flexDirection: { xs: "column", md: "row" } }} gap={"30px"}>
        <Box sx={{ width: { md: "330px", xs: "100%" }, background: "#F1F1F1", paddingY: "20px" }}>
            {renderFilterControls()}
            {metadata && <Box sx={{ "& button": { borderRadius: "5px", marginTop: "10px", marginRight: "20px", paddingX: "30px", }, paddingX: "28px" }}>
                <Button variant="contained" onClick={() => {
                    setPageNo(0);
                    performSearch();
                    scrollTo({ top: 0 }); //eslint-disable-line
                }}>Submit</Button>
                <Button variant="outlined" onClick={() => {
                    setValues({});
                    setPageNo(0);
                    setCompanyList([]);
                    setNoCompanyFound(false);
                }}>Reset</Button>
            </Box>}
        </Box>
        <Box sx={{ minWidth: "calc(100% - 350px)" }}>
            <Box display={"flex"} sx={{ flexDirection: { md: "row", xs: "column" }, paddingY: "10px", border: { md: "1px solid #EEF0F4", xs: "2px solid #EEF0F4" }, borderRadius: "4px", marginBottom: "15px", paddingLeft: "20px" }}>
                <Box sx={{ textAlign: { md: "left", xs: "center" } }}>
                    <FormControl sx={{ width: { xs: "80%", md: "auto" } }}>
                        <Select
                            displayEmpty
                            value={currentPageSize || ''}
                            // onChange={(e) => { setPageSize(parseInt(e.target.value as string)) }}
                            onChange={(e) => handlePageSizeChange(parseInt(e.target.value as string))}
                            input={<OutlinedInput />}
                            sx={{ fontSize: { md: "12px", xs: "14px" }, height: { xs: "40px", md: "30px" }, border: "0px" }}

                            MenuProps={{ PaperProps: { style: { fontSize: "12px" } } }}

                        >
                            <MenuItem
                                key={20}
                                value={20}
                                style={{ fontSize: "12px" }}
                            >
                                20 companies/page
                            </MenuItem>
                            <MenuItem
                                key={50}
                                value={50}
                                style={{ fontSize: "12px" }}
                            >
                                50 companies/page
                            </MenuItem>
                            <MenuItem
                                key={100}
                                value={100}
                                style={{ fontSize: "12px" }}
                            >
                                100 companies/page
                            </MenuItem>

                        </Select>
                    </FormControl>
                    {companyList && companyList.length > 0 && <Typography component={"p"} sx={{ fontFamily: 'Oxygen', color: "grey", fontSize: "10px", margin: { md: "5px 0px 0px 15px", xs: "5px 0px 0px 0px" } }}>{pageNo * pageSize + companyList.length} out of {totalRecords}</Typography>}
                </Box>
                <Box sx={{
                    marginLeft: "auto", marginRight: { xs: "auto", md: "0px" }, "& button": {
                        backgroundColor: "transparent",
                        borderRadius: "0px",
                        boxShadow: "none",
                        padding: "0px",
                        "&:disabled": {
                            backgroundColor: "transparent",
                            opacity: 0.5
                        },
                        "&:hover": {
                            backgroundColor: "transparent"
                        }
                    },
                    paddingTop: companyList && companyList.length > 0 ? "8px" : "auto"
                }}>
                    <Button variant="contained" disabled={pageNo <= 0} onClick={() => { setPageNo(pageNo - 1) }}><img src="/LeftButton.png" /></Button>
                    <Button variant="contained" disabled={companyList && (companyList.length < pageSize)} onClick={() => { setPageNo(pageNo + 1) }}><img src="/RightButton.png" /></Button>
                </Box>
            </Box>
            {isCompanyListLoading && <CircularProgress sx={{ position: "fixed", left: "50%", top: "20%" }} color="primary" />}
            {noCompanyFound && <Typography style={{
                marginTop: "100px",
                "fontSize": "20px",
                "textAlign": "center"
            }} component={"h3"}> No company found that matches the filter.</Typography>}
            {renderCompanyList()}
        </Box>
        {showGotoPlanDialog && (
            <UnlistedScreenerGoToPlan
                isOpen={showGotoPlanDialog}
                handleClose={handleGotoPlanDialogClose}
                credits={reward?.credits}
            />
        )}
        {showUnlockDialog && (
            <UnlistedScreenerUnlock
                isOpen={showUnlockDialog}
                handleClose={handleUnlockDialogClose}
                credits={reward?.credits}
                handleConfirm={unlockCompany}
            />
        )}
    </Box>

}
import { Expand } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";

export function UnlistedScreenerItemBox(props: { data: any, onClick: (data: any) => void , showUnlock?: boolean}) {

    const [isExpand, setExpand] = useState(false);
    const { data } = props;
    const readmoreLength = 60;

    const getMoreDetail = (str) => {
        if (str) {
            if (str.length > readmoreLength) {
                return <>
                    {!isExpand ? str.substring(0, readmoreLength) : str}
                    <Typography component={"span"} color="primary" sx={{ cursor: "pointer", fontSize: "12px" }} onClick={() => { setExpand(!isExpand); }} >..{isExpand ? `hide details` : `more details`}</Typography>
                </>

            } else {
                return str;
            }
        }
    }

    return <Box sx={{ marginBottom: "24px", width: { xs: "auto", md: "calc(33.33% - 13.33px)" } }}>
        <Box display={"flex"} flexDirection={"column"} sx={{
            borderRradius: "5px",
            border: "1px solid #009262",
            borderRadius: "5px"
        }}>
            <Box sx={{ marginTop: "10px", textAlign: "center" }}>
                <img src="/unlisted-image-icon.png" />
            </Box>
            <Box sx={{
                padding: "16px 24px",
                "& ul": {
                    fontFamily: 'Oxygen',
                    fontSize: "12px",
                    margin: "7px 0px 0px 0px",
                    paddingLeft: "0px",
                    color: "#666"
                },
                "& li": {
                    marginBottom: "7px",
                    listStyle: "none"
                }
            }}>
                {/* <Link target={"_blank"} style={{ textDecoration: "none" }} to={`/unlistedcompany?code=${data._id}`}> <Typography color="primary" sx={{ fontSize: "16px", fontWeight: 700 }} component={"div"}>{data.companyName}</Typography></Link> */}
                {props.showUnlock && data?.unlocked && data?.unlocked === true ?
                    <Box sx={{
                        "& div": {
                            textOverflow: "ellipsis",
                            whiteSpace: isExpand ? "normal" : "nowrap",
                            overflow: "hidden"
                        }
                    }} >
                        <Link target={"_blank"} style={{
                            textDecoration: "none"

                        }} to={`/unlistedcompany?code=${data._id}`}> <Typography color="#009262" sx={{ fontSize: "16px", fontWeight: 500 }} component={"div"}>{data.companyName}</Typography></Link></Box> :
                    <div style={{
                        textDecoration: "none",
                        cursor: "pointer",
                        textOverflow: "ellipsis",
                        whiteSpace: isExpand ? "normal" : "nowrap",
                        overflow: "hidden"
                    }} onClick={() => props.onClick(data)} > <Typography color="#009262" sx={{ fontSize: "16px", fontWeight: 500 }} >{data.companyName}</Typography></div>}

                {/* <div style={{ textDecoration: "none", cursor: "pointer" }} onClick={onCompanyNameClick} > <Typography color="primary" sx={{ fontSize: "16px", fontWeight: 700 }} >{data.companyName}</Typography></div> */}
                {/* <Typography sx={{ fontSize: "12px" }}>{props.description}</Typography> */}
                <ul style={{
                    minHeight: "40px"
                }}>
                    {data.principalBusinessActivity && <li>
                        {getMoreDetail(data.principalBusinessActivity)}
                    </li>}
                    {/* <li>{data.registeredOfficeAddress}</li> */}
                </ul>
                <div style={{ background: "#E6E6E6", height: "1px", marginBottom: "10px" }} />
                <Box sx={{
                    "& span": {
                        borderRadius: "2px",
                        padding: "3px 10px",
                        fontSize: "11px",
                        border: "1px solid #009262",
                        flexGrow: 1,
                        textAlign: "center"
                    },
                    display: "flex",
                    gap: "10px",
                    marginTop: "10x"
                }}>
                    <Typography sx={{ background: "#009262", color: "#FFFFFF" }}>{data.companyStatus}</Typography>
                    {data.registeredState?.length > 0 && <Typography sx={{ background: "#E6FFF7", color: "#3C3C43" }}>{data.registeredState}</Typography>}
                </Box>
                <Box sx={{
                    "& span": {
                        borderRadius: "2px",
                        padding: "3px 10px",
                        fontSize: "11px",
                        border: "1px solid #009262",
                        flexGrow: 1,
                        textAlign: "center",
                        background: "#F8F8F8",
                        color: "#3C3C43",
                    },
                    display: "flex",
                    gap: "10px",
                    marginTop: "10px"
                }}>
                    <Typography>Year: {data.year || "NA"}</Typography>
                    <Typography>PAT: {data.pat || "NA"}</Typography>
                </Box>
                <Box sx={{
                    "& span": {
                        borderRadius: "2px",
                        color: "#3C3C43",
                        padding: "5px 10px",
                        fontSize: "11px",
                        background: "#F8F8F8",
                        border: "1px solid #009262",
                        textAlign: "center"
                    },
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    marginTop: "10px"
                }}>
                    {<Typography>Authorized Capital: {data.authorizedCapital || "Not Available"}</Typography>}
                    {<Typography>Paid-Up Capital: {data.paidUpCapital || "Not Available"}</Typography>}
                    {data.net_worth && <Typography>Net Worth: {data.net_worth}</Typography>}
                    {<Typography>Total Income: {data.total_income || "Not Available"}</Typography>}
                    {data.total_expense && <Typography>Total Expense: {data.total_expense}</Typography>}
                    {data.pbt && <Typography>PBT: {data.pbt}</Typography>}
                    {data.income_tax && <Typography>Income Tax: {data.income_tax}</Typography>}
                </Box>
            </Box>
        </Box>
    </Box >
}